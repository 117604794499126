<template>
  <div>
    <CompleteTable
      v-if="!loading"
      :sorted-field="sortedField"
      :data="applicants"
      :sort-mapping="sortMapping"
      :title="'applicants not eligibles'"
      :total="applicantsTotal"
      :fetch-path="'convo/fetchApplicants'"
      :export-path="'lines/export'"
      :filter-path="'convo/fetchApplicants'"
      :actions="true"
      @selectedItems="saveSelectedItems"
    >
      <template v-slot="{field}">
        <td
          class="text-end"
        >
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0"
            right
          >
            <template #button-content>
              <i data-feather="more-vertical" />
            </template>
            <div
              class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
            >
              <b-dropdown-item @click="selectedItem = field, $store.dispatch('modals/toggleFormData', true)">
                <i
                  class="me-50"
                  data-feather="eye"
                /> View form data
              </b-dropdown-item>
              <b-dropdown-item @click="selectedItem = field, $store.dispatch('modals/toggleFormEvaluation', true)">
                <i
                  class="me-50"
                  data-feather="eye"
                /> View form evaluation
              </b-dropdown-item>
              <b-dropdown-item @click="$store.dispatch('modals/toggleUserTraces', true), traceUser = field">
                <i
                  class="me-50"
                  data-feather="eye"
                /> View traces
              </b-dropdown-item>
            </div>
          </b-dropdown>
          <a
            href=""
            class="btn btn-icon btn-light-secondary d-block d-sm-none"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasMenu"
            aria-controls="offcanvasBottom"
          ><i
            data-feather="more-vertical"
          /></a>
        </td>
      </template>
      <template #customfilters>
        <div class="mb-1">
          <label
            for="nameseniorcall"
            class="form-label"
          >Candidate</label>
          <input
            v-model="filters.candidate"
            type="text"
            class="form-control"
            name="nameseniorcall"
            @change="saveFilter({ customLabel: true, name: `Candidate: ${$event.target.value}`, value: $event.target.value }, 'candidate')"
          >

        </div>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >Status</label>
          <select
            id=""
            v-model="filters.status"
            name=""
            class="form-select"
            @change="saveFilter({ customLabel: true, name: `Status: ${getStatusName($event.target.value)}`, value: $event.target.value !== '0' ? $event.target.value : null }, 'status')"
          >
            <option value="0">
              All applicants
            </option>
            <option value="3">
              Not eligible
            </option>
            <option value="4">
              1st round
            </option>
            <option value="5">
              2nd round
            </option>
            <option value="6">
              Ranked
            </option>
            <option value="7">
              Offered
            </option>
            <option value="8">
              Position accepted
            </option>
          </select>
        </div>
        <div class="mb-1">
          <div class="row">
            <div class="col-6">
              <label
                for="nameseniorcall"
                class="form-label"
              >AVG Grade min.</label>
              <input
                v-model="filters.avg_min"
                type="number"
                step="0.01"
                class="form-control"
                name="nameseniorcall"
                @change="saveFilter({ customLabel: true, name: `AVG Grade min.: ${$event.target.value}`, value: $event.target.value}, 'avg_min')"
              >
            </div>
            <div class="col-6">
              <label
                for="nameseniorcall"
                class="form-label"
              >AVG Grade max</label>
              <input
                v-model="filters.avg_max"
                type="number"
                step="0.01"
                class="form-control"
                name="nameseniorcall"
                @change="saveFilter({ customLabel: true, name: `AVG Grade max.: ${$event.target.value}`, value: $event.target.value}, 'avg_max')"
              >
            </div>
          </div>
        </div>
      </template>
      <template #info-sheet-item>
        <div class="offcanvas-body offcanvas-body--view">

          <h4 class="mb-2">
            <strong class="text-success">{{ selectedItems.length }}</strong> selected applicants
          </h4>

          <ul
            id="basic-list-group"
            class="list-group"
          >
            <li class="list-group-item draggable">
              <div class="d-flex">
                <div class="more-info">
                  <div class="form-check">
                    <input
                      id="tableAction1"
                      v-model="actions.moveRoundZero"
                      type="checkbox"
                      class="form-check-input"
                    >
                    <label
                      class="form-check-label"
                      for="tableAction1"
                    >Move to round 0</label>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item draggable">
              <div class="d-flex">
                <div class="more-info">
                  <div class="form-check">
                    <input
                      id="tableAction1"
                      v-model="actions.moveRoundOne"
                      type="checkbox"
                      class="form-check-input"
                    >
                    <label
                      class="form-check-label"
                      for="tableAction1"
                    >Move to round 1</label>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="offcanvas-footer mt-auto">
          <a
            href="#"
            title="apply actions"
            class="btn btn-primary mb-1 d-grid w-100"
            @click="applyActions"
          >Apply actions</a>
        </div>
      </template>
    </CompleteTable>
    <ShowFormEvaluation :selected="selectedItem" />
    <ManageUserTraces :user="traceUser" />
    <ShowFormData :selected="selectedItem" />
    <div
      v-if="loading"
      id="loading-bg"
    >
      <div
        class="loading"
      >
        <div class="effect-1 effects" />
        <div class="effect-2 effects" />
        <div class="effect-3 effects" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CompleteTable from '@/views/back/partials/components/CompleteTable.vue'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import Vue from 'vue'
import ManageUserTraces from '@/views/back/partials/offcanvas/ManageUserTraces.vue'
import ShowFormEvaluation from './ShowFormEvaluation.vue'
import ShowFormData from './ShowFormData.vue'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
    ManageUserTraces,
    ShowFormData,
    ShowFormEvaluation,
  },
  props: {
    status: { type: Number, required: false, default: () => null },
  },
  data() {
    return {
      selectedItem: {},
      traceUser: {},
      sortedField: 'Name',
      defaultFields: [
        {
          name: 'Name',
          checked: true,
          order: 1,
        },
        {
          name: 'Area',
          checked: true,
          order: 2,
        },
        {
          name: 'Institution',
          checked: true,
          order: 3,
        },
        {
          name: 'Status',
          checked: true,
          order: 4,
        },
        {
          name: 'Evaluators',
          checked: true,
          order: 5,
        },
        {
          name: 'ERC',
          checked: true,
          order: 6,
        },
        {
          name: 'Traces',
          checked: true,
          order: 7,
        },
      ],
      sortMapping: {
        Name: 'full_name',
        Area: 'area',
        Institution: 'institution_name',
        Status: 'status.status_table',
        Evaluators: 'evaluators',
        ERC: 'erc',
        Traces: 'traces.length',
      },
      actions: {
        moveRoundZero: false,
        moveRoundOne: false,
      },
      selectedItems: [],
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      applicants: 'convo/applicants',
      applicantsTotal: 'convo/applicantsTotal',
      fields: 'modals/tableSettingsFields',
      filters: 'filters/filters',
    }),
  },
  async mounted() {
    this.$store.dispatch('modals/fetchUserFields', 'not_eligible')
    await this.$store.dispatch('convo/fetchApplicants', { id: this.$route.params.id, status: { value: this.status }, ...this.filters })
    this.loading = false

    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'not_eligible',
      })
    }
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    applyActions() {
      if (this.selectedItems.length > 0) {
        Vue.swal({
          title: 'Perform the following actions on the selected publications?',
          html: this.getActionsString(),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Yes, apply!',
        }).then(result => {
          if (result.isConfirmed) {
            const itemIds = this.selectedItems.map(el => el.id)
            this.$store.dispatch('payrolls/downloadZip', { itemIds }).then(() => {
              this.$store.dispatch('payrolls/filter')
              this.$toastr.success('', 'Actions applied successfully!')
              this.selectedItems = []
              this.$store.dispatch('modals/toggleInfoItemSheet', false)
            })
          }
        })
      } else {
        this.$toastr.error('', 'No items selected!')
      }
    },
    getActionsString() {
      let str = ''

      if (this.actions.moveRoundZero) {
        str += '<p class="text-success">Move the selected applicants to Round 0</p>'
      }

      if (this.actions.moveRoundOne) {
        str += '<p class="text-success">Move the selected applicants to Round 1</p>'
      }

      return str
    },
    saveSelectedItems(items) {
      this.selectedItems = items
    },
    saveFilter(value, field) {
      if (!value || (!value.value || (value.value === 'null' || (Array.isArray(value.value) && !value.value.length)))) {
        this.$store.dispatch('filters/deleteFilters', field)
      } else {
        this.$store.dispatch('filters/saveFilters', {
          value,
          field,
        })
      }
    },
    getStatusName(value) {
      let str = ''
      switch (value) {
        case '0':
          str = 'All applicants'
          break
        case '3':
          str = 'Not elegible'
          break
        case '4':
          str = '1st round'
          break
        case '5':
          str = '2nd round'
          break
        case '6':
          str = 'Ranked'
          break
        case '7':
          str = 'Offered'
          break
        case '8':
          str = 'Position accepted'
          break
        default:
          break
      }
      return str
    },
  },
}
</script>
