<template>
  <div>
    <div class="select-container d-flex align-items-center justify-content-between">
      <div>
        <h4 class="mb-0">
          <strong v-if="area">Ranked: {{ area.areaName }}</strong>
          <strong v-else>Select an area to start ranking</strong>
        </h4>
      </div>
      <div>
        <v-select
          v-model="area"
          style="width: 400px"
          label="areaName"
          :options="convo.areas"
          :get-option-key="option => option.areaId"
          @input="getRankeds"
        />
      </div>
    </div>
    <br>
    <div v-if="area">
      <CompleteTable
        v-if="!loading"
        :sorted-field="sortedField"
        :data="rankeds"
        :sort-mapping="sortMapping"
        :title="`applicants (${totalRankeds} rankeds)`"
        :total="totalUsers"
        :fetch-path="'convo/fetchApplicants'"
        :filter-path="'convo/fetchApplicants'"
        :ranked-path="'convo/rankeds'"
        :actions="true"
        @selectedItems="saveSelectedItems"
      >
        <template v-slot="{field}">
          <td
            class="text-end"
          >
            <b-dropdown
              variant="link"
              no-caret
              toggle-class="p-0"
              right
            >
              <template #button-content>
                <i data-feather="more-vertical" />
              </template>
              <div
                class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
              >
                <b-dropdown-item @click="selectedItem = field, $store.dispatch('modals/toggleFormEvaluation', true)">
                  <i
                    class="me-50"
                    data-feather="eye"
                  /> View form evaluation
                </b-dropdown-item>
                <b-dropdown-item @click="$store.dispatch('modals/toggleUserTraces', true), traceUser = field">
                  <i
                    class="me-50"
                    data-feather="eye"
                  /> View traces
                </b-dropdown-item>
                <div class="dropdown-divider" />
                <b-dropdown-item @click="selectedItem = field, $store.dispatch('modals/toggleFormData', true)">
                  <i
                    class="me-50"
                    data-feather="eye"
                  /> View form data
                </b-dropdown-item>
                <div class="dropdown-divider" />
                <b-dropdown-item
                  :href="$router.resolve({ name: 'admin.users.edit', params: { id: field.user_id }, query: { actAs: $route.query.actAs } }).href"
                  target="_blank"
                >
                  <i
                    class="me-50"
                    data-feather="edit"
                  />
                  Edit profile
                </b-dropdown-item>
              </div>
            </b-dropdown>
            <a
              href=""
              class="btn btn-icon btn-light-secondary d-block d-sm-none"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasMenu"
              aria-controls="offcanvasBottom"
            ><i
              data-feather="more-vertical"
            /></a>
          </td>
        </template>
        <template #customfilters>
          <div
            class="mb-1"
          >
            <label
              for="nameseniorcall"
              class="form-label"
            >Candidate</label>
            <v-select
              v-model="filters.candidate"
              label="full_name"
              :filter="customFilter"
              :options="applicants"
              :get-option-key="option => option.id"
              :placeholder="'Type 3 or more characters to search...'"
              :reduce="e => e.user_id"
              @search="onSearch({ candidate: $event }, 'convo/fetchApplicants')"
              @input="saveFilter({ customLabel: true, name: `Candidate: ${$event ? $event.full_name : ''}`, value: $event }, 'candidate')"
            />
            <hr>
          </div>
          <div
            v-if="!status"
            class="mb-1"
          >
            <label
              for=""
              class="form-label"
            >Status</label>
            <select
              id=""
              v-model="filters.status"
              name=""
              class="form-select"
              @change="saveFilter({ customLabel: true, name: `Status: ${getStatusName($event.target.value)}`, value: $event.target.value !== '0' ? $event.target.value : null }, 'status')"
            >
              <option value="0">
                All applicants
              </option>
              <option value="3">
                Not eligible
              </option>
              <option value="4">
                1st round
              </option>
              <option value="5">
                2nd round
              </option>
              <option value="6">
                Ranked
              </option>
              <option value="7">
                Offered
              </option>
              <option value="8">
                Position accepted
              </option>
            </select>
          </div>
          <div
            class="mb-1"
          >
            <label
              for="nameseniorcall"
              class="form-label"
            >Area</label>
            <v-select
              v-model="filters.area"
              label="name"
              :options="areas"
              :get-option-key="option => option.id"
              @input="saveFilter({ customLabel: true, name: `Area: ${$event ? $event.name : ''}`, value: $event }, 'area')"
              @search="onSearch({ name: $event }, 'areas/filter')"
            />
          </div>
        </template>
        <template #info-sheet-item>
          <div class="offcanvas-body offcanvas-body--view">

            <h4 class="mb-2">
              <strong class="text-success">{{ selectedItems.length }}</strong> selected applicants
            </h4>

            <ul
              id="basic-list-group"
              class="list-group"
            >
              <li class="list-group-item draggable">
                <div class="d-flex">
                  <div class="more-info">
                    <div class="form-check">
                      <input
                        id="tableAction1"
                        v-model="actions.moveRound"
                        type="radio"
                        class="form-check-input"
                        name="actions"
                        value="7"
                      >
                      <label
                        class="form-check-label"
                        for="tableAction1"
                      >Move to offered</label>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="offcanvas-footer mt-auto">
            <a
              href="#"
              title="apply actions"
              class="btn btn-primary mb-1 d-grid w-100"
              @click="applyActions"
            >Apply actions</a>
          </div>
        </template>
      </CompleteTable>
      <ShowFormEvaluation :selected="selectedItem" />
      <ManageUserTraces :user="traceUser" />
      <ShowFormData :selected="selectedItem" />
      <div
        v-if="loading"
        id="loading-bg"
      >
        <div
          class="loading"
        >
          <div class="effect-1 effects" />
          <div class="effect-2 effects" />
          <div class="effect-3 effects" />
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'
import CompleteTable from '@/views/back/partials/components/CompleteTable.vue'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import ManageUserTraces from '@/views/back/partials/offcanvas/ManageUserTraces.vue'
import vSelect from 'vue-select'
import ShowFormEvaluation from './ShowFormEvaluation.vue'
import ShowFormData from './ShowFormData.vue'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
    ShowFormEvaluation,
    ManageUserTraces,
    ShowFormData,
    vSelect,
  },
  props: {
    status: { type: Number, required: false, default: () => null },
  },
  data() {
    return {
      area: null,
      selectedItem: {},
      traceUser: {},
      sortedField: 'Rank',
      defaultFields: [
        {
          name: 'Normalized grade',
          checked: true,
          order: 2,
        },
        {
          name: 'Full name',
          checked: true,
          order: 3,
        },
        {
          name: 'Area',
          checked: true,
          order: 4,
        },
        {
          name: 'Institution',
          checked: true,
          order: 5,
        },
        {
          name: 'Status',
          checked: true,
          order: 6,
        },
        {
          name: 'Age',
          checked: true,
          order: 7,
        },
      ],
      sortMapping: {
        'Normalized grade': 'normalized_grade',
        'Full name': 'full_name',
        Area: 'area.name',
        Institution: 'institution.name',
        Status: 'status_table',
        Age: 'age',
      },
      loading: true,
      actions: {},
      selectedItems: [],
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      rankeds: 'convo/rankeds',
      fields: 'modals/tableSettingsFields',
      totalRankeds: 'convo/totalRankeds',
      totalUsers: 'convo/totalUsers',
      filters: 'filters/filters',
      applicants: 'convo/applicants',
      areas: 'areas/areas',
    }),
  },
  async mounted() {
    await this.$store.dispatch('modals/fetchUserFields', 'rankeds')
    this.loading = false

    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'rankeds',
      })
    }
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    customFilter(option, query) {
      // Perform case-insensitive and accent-insensitive matching
      return this.applicants.filter(e => e.full_name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(query))
    },
    saveSelectedItems(items) {
      this.selectedItems = items
    },
    applyActions() {
      if (this.selectedItems.length > 0) {
        Vue.swal({
          title: 'Offer a position on the selected applicants?',
          html: '',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Yes',
        }).then(result => {
          if (result.isConfirmed) {
            const itemIds = this.selectedItems.map(el => el.user_id)
            this.$store.dispatch('users/changeStatus', { actions: this.actions, itemIds, convoId: this.convo.id }).then(() => {
              this.$store.dispatch('convo/fetchApplicants', { id: this.$route.params.id, ...this.filters })
              this.$store.dispatch('convo/fetchRankeds', { area: this.area.areaId })
              this.$toastr.success('', 'Actions applied successfully!')
              this.$store.dispatch('modals/toggleInfoItemSheet', false)
            })
          }
        })
      } else {
        this.$toastr.error('', 'No items selected!')
      }
    },
    async getRankeds(data) {
      this.$store.dispatch('pagination/clearPage')
      await this.$store.dispatch('convo/fetchRankeds', { area: data.areaId })
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 100)
    },
    saveFilter(value, field) {
      if (!value || (!value.value || (value.value === 'null' || (Array.isArray(value.value) && !value.value.length)))) {
        this.$store.dispatch('filters/deleteFilters', field)
      } else {
        this.$store.dispatch('filters/saveFilters', {
          value,
          field,
        })
      }
    },
    getStatusName(value) {
      let str = ''
      switch (value) {
        case '0':
          str = 'All applicants'
          break
        case '3':
          str = 'Not elegible'
          break
        case '4':
          str = '1st round'
          break
        case '5':
          str = '2nd round'
          break
        case '6':
          str = 'Ranked'
          break
        case '7':
          str = 'Offered'
          break
        case '8':
          str = 'Position accepted'
          break
        default:
          break
      }
      return str
    },
  },
}
</script>
